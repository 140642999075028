<template>
  <div
    class="siteBody rtl"
    data-aos-easing="ease"
    data-aos-duration="400"
    data-aos-delay="0"
  >
    <div class="_wuilt_free_banner">
      <div class="_wuilt_free_logo">
        <img src="../assets/image/icon.png" alt="" class="img-icon-top" />
      </div>
      <p class="_wuilt_free_intro">
        خليك فالبيت... خليك في آمان. اطلب اونلاين و الشحن مجاني داخل القاهره
        والجيزه فقط و الدفع عند الإستلام
      </p>
      <a
        class="_wuilt_free_btn animate__animated animate__fadeInLeft animate__delay-1s"
        :href="'tel:' + home.phone"
        >{{ home.phone }}</a
      >
    </div>
    <div>
      <Menubar class="container d-flex py-3" :model="items">
        <template #start>
          <router-link to="/">
            <img
              alt="logo"
              :src="$baseUploadURL + home.logo"
              height="40"
              class="mr-2 animate__animated animate__rubberBand animate__delay-1s"
            />
          </router-link>
        </template>
      </Menubar>
    </div>
    <div id="overflow-hidden">
      <div
        style="
          background-color: rgb(255, 255, 255);
          background-position: center center;
          background-size: cover;
        "
      >
        <div class="">
          <div class="_hero_n2ocl_9">
            <div class="_content_n2ocl_32 container">
              <div class="_rowStyle_n2ocl_44">
                <div class="row">
                  <div class="col-md-7">
                    <div
                      class="_heroContent_n2ocl_68 animate__animated animate__fadeInRight animate__delay-1s"
                    >
                      <div><h1>لنبدأ العمل سويًّا</h1></div>
                      <div>
                        <p>
                          <strong
                            >لا تتردد ابدا في التواصل معنا بخصوص اي استشارة
                            مجانيه</strong
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 d-xs-none d-md-flex _actionCol_n2ocl_28">
                    <div class="_btnColumn_n2ocl_85">
                      <a
                        class="btn _buttonone_n2ocl_117 animate__animated animate__fadeInLeft animate__delay-1s"
                        href="/#tohome"
                      >
                        ابدأ الان
                      </a>
                      <router-link
                        class="btn _button_n2ocl_1 animate__animated animate__fadeInLeft animate__delay-1s"
                        to="/contactus"
                        >اتصل بنا</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container infotec" style="width: 90vw;max-width: 90vw;">
      <div class="row text-center">
        <div class="col-md-3 pt-3" style="direction: rtl">
          البريد الالكتروني
          <a href="mailto:info@sada-eg.com">
            info@sada-eg.com
          </a>
        </div>
        <div class="col-md-3 pt-3">
          <p >
            شكاوى ومقترحات
            <a href="tel:01128385892">01128385892</a>
          </p>
        </div>
        <div class="col-md-3 pt-3">
          <p >
            خدمة العملاء
            <a href="tel:01124574775">01124574775</a>
          </p>
        </div>
        <div class="col-md-3 pt-3">
          <p >
            المبيعات
            <a href="tel:01110580260">01110580260</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";

export default {
  components: {
    Menubar,
  },
  name: "app-header",
  props: {
    sections: Array,
    home: {
      logo: String,
      phone: String,
    },
    pages: Array,
  },
  data() {
    return {
      items: [
        {
          label: "الرئيسيه",
          to: "/",
        },
      ],
    };
  },
  watch: {
    pages(val) {
      if (val) {
        for (const item of val) {
          if (item.slug == "About-Us") {
            this.items.push({
              label: item.name,
              to: "/page/" + item.slug,
            });
          }
        }
      }
    },
    sections(val) {
      if (val) {
        for (const item of val) {
          const items = [{
            label:"الكل",
            to: "/sections/" + item.slug,
          }];
          for (const itm of item.products) {
            items.push({
              label: itm.name,
              to: "/product/" + itm.slug,
            });
          }
          this.items.push({
            label: item.name,
            items,
          });
        }
      }
    },
  },
};
</script>
<style>
.infotec {
  color: #000;
  font-weight: bold;
}

.infotec a{
  color: #1b579a;
}
</style>