import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/page/:id',
    name: 'page',
    component: () => import('../views/page.vue'),
  },
  {
    path: '/sections/:id',
    name: 'sections',
    component: () => import('../views/sections.vue'),
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('../views/product.vue'),
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/contactus.vue'),
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, y: 0 };
  },
});

export default router;
