<template>
  <div
    class="siteBody rtl animate__animated animate__backInUp animate__delay-1s"
  >
    <div id="overflow-hidden">
      <Galleria
        :value="slider"
        :responsiveOptions="responsiveOptions"
        :numVisible="5"
        :circular="true"
        style="max-width: 640px"
        :showItemNavigators="true"
        :showThumbnails="false"
        :autoPlay="true"
        :transitionInterval="2000"
      >
        <template #item="slotProps">
          <img
            :src="$baseUploadURL + slotProps.item.image"
            alt=""
            style="width: 100%; display: block; max-height: 1200px"
          />
        </template>
      </Galleria>

      <div>
        <div class="">
          <section class="_video_section_16ti3_1">
            <div class="container">
              <div class="row">
                <div
                  class="col-11 col-md-5 align-self-center _section_header_16ti3_5 animate__animated animate__fadeInRight animate__delay-2s"
                >
                  <div><h2>مرحبا بكم فى sada</h2></div>
                  <div>
                    <p>{{ home.aboutUs }}</p>
                  </div>
                  <a
                    class="btn _button_16ti3_25"
                    target="_self"
                    :href="home.aboutUsLink"
                  >
                    المزيد
                  </a>
                </div>
                <div
                  class="col-12 col-md-6 animate__animated animate__fadeInLeft animate__delay-2s"
                >
                  <div class="_video_16ti3_1">
                    <div class="video_videoPlayerContainer__sTleQ">
                      <div
                        style="width: 100%; height: 100%"
                        class="video_videoPlayer__zJQS_"
                      >
                        <div style="width: 100%; height: 100%">
                          <iframe
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            :src="
                              'https://www.youtube.com/embed/' +
                              home.aboutUsYouTube
                            "
                            id="widget2"
                            width="100%"
                            height="100%"
                            frameborder="0"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div>
        <!-- <div class="">
          <div class="_wrapper_pogrm_1">
            <div class="_section_pogrm_5">
              <div
                class="_w-layout-grid_pogrm_23 _content7grid_pogrm_35 animate__animated animate__rotateInDownRight animate__delay-2s"
              >
                <div
                  class="_wnode33e4c0cdd70ab6116a24_pogrm_52 _content7contentwrap_pogrm_63"
                >
                  <h2 class="_size1text_pogrm_85">عنا</h2>
                  <div class="_paragraph70_pogrm_94">
                    <p>
                      {{ home.weHaveText }}
                    </p>
                  </div>
                </div>
                <div class="_wnode33e4c0cdd710b6116a24_pogrm_101">
                  <img
                    :src="$baseUploadURL + home.weHavePicture"
                    class="_content7image_pogrm_113"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div>
        <div class="">
          <section>
            <div
              class="_containerImage_18423_1"
              style="
                background-image: url('/b.jpg');
                background-position: center center;
                background-size: cover;
              "
            >
              <div class="_overlay_18423_5"></div>
              <div class="container">
                <div class="_intro_18423_14">
                  <h2>خدماتنا</h2>
                  <p>
                    نمتلك خبرة طويلة في مجالنا، ولذلك نستطيع تقديم خدمات متميّزة
                    لك.
                  </p>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="_items_18423_55">
                <div class="row _itemsRow_18423_55">
                  <div
                    class="col-lg-4 col-md-6 col-sm-6 col-xs-12 animate__animated animate__rotateInDownRight animate__delay-2s"
                    v-for="item of sections"
                    :key="item.id"
                  >
                    <div class="_itemContainer_18423_34">
                      <div class="_item_18423_34">
                        <i class="pi pi-star _serviceIcon_18423_49"></i>
                        <div class="_itemInfo_18423_59">
                          <div class="_itemTitle_18423_39">
                            <h4>{{ item.name }}</h4>
                          </div>
                          <div>
                            <p>
                              {{ item.body }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div id="tohome">
        <div class="">
          <div class="_body_16rkg_9 container">
            <div class="_servicesTitle_16rkg_107">
              <h2>خدماتُنا</h2>
              <p>
                نمتلك خبرة طويلة في مجالنا، ولذلك نستطيع تقديم خدمات متميّزة لك.
              </p>
            </div>
            <div class="container">
              <div
                class="_item_16rkg_13"
                v-for="item of sections"
                :key="item.id"
              >
                <div class="row">
                  <div class="_itemImageCol_16rkg_49 col-lg-5 col-md-6">
                    <div
                      class="_image_16rkg_69 animate__animated animate__rotateInDownRight animate__delay-2s"
                      :style="
                        `padding-top: 66.6667%;
                        background-image: url('` +
                        $baseUploadURL +
                        item.image +
                        `');
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;`
                      "
                    ></div>
                  </div>
                  <div class="_itemInfoCol_16rkg_22 col-lg-7 col-md-7">
                    <div
                      class="_itemInfo_16rkg_22 animate__animated animate__rotateInDownLeft animate__delay-2s"
                    >
                      <div>
                        <h4>
                          {{ item.name }}
                        </h4>
                      </div>
                      <div>
                        <p>{{ item.body }}</p>
                      </div>
                      <router-link
                        :to="'/sections/' + item.slug"
                        class="btn _btnread_16rkg_134 btn"
                        >المزيد</router-link
                      >
                    </div>
                  </div>
                </div>
                <div v-if="item.products.length > 0">
                  <!-- prod -->
                  <div
                    class="surface-section px-4 py-8 md:px-6 lg:px-8"
                    style="direction: rtl"
                  >
                    <div class="row _itemsRow_18423_55">
                      <div
                        class="col-lg-4 col-md-6 col-sm-6 col-xs-12 animate__animated animate__rotateInDownRight animate__delay-2s"
                        v-for="itm of item.products"
                        :key="itm.id"
                      >
                        <div class="_itemContainer_18423_34">
                          <div class="_item_18423_34" style="padding: 0">
                            <router-link :to="'/product/' + itm.slug">
                              <img
                                :src="$baseUploadURL + itm.image"
                                class="w-full"
                                style="height: 262px; border-radius: 0"
                              />
                            </router-link>
                            <div
                              class="_itemInfo_18423_59"
                              style="padding: 24px 25px"
                            >
                              <div class="_itemTitle_18423_39">
                                <h4>
                                  <router-link
                                    :to="'/product/' + itm.slug"
                                    style="color: #1c1c1c"
                                  >
                                    {{ itm.name }}
                                  </router-link>
                                </h4>
                              </div>
                              <div>
                                <!-- <p>
                                  {{
                                    itm.body.slice(0, 200) +
                                    (itm.body.length > 200 ? "..." : "")
                                  }}
                                </p> -->
                                <div class="text-900 text-xl font-medium mt-3">
                                  <p class="price" v-if="itm.newPrice > 0">
                                    ج.م {{ itm.newPrice }}
                                    <del>ج.م {{ itm.price }}</del>
                                  </p>
                                  <p class="price" v-else>
                                    ج.م {{ itm.price }}
                                  </p>
                                </div>
                                <div>
                                  <router-link
                                    :to="'/product/' + itm.slug"
                                    class="bg-blue-100 text-blue-700 font-bold px-2 py-1 cart-btn more"
                                    >التفاصيل</router-link
                                  >
                                  <span
                                    @click="openBey(itm.id)"
                                    class="bg-blue-100 text-blue-700 font-bold px-2 py-1 cart-btn pay"
                                  >
                                    <i class="pi pi-cart-plus"></i>
                                    طلب</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- endprod -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header="طلب المنتج"
        :visible="display"
        :style="{ width: '50vw' }"
        :closable="false"
      >
        <form class="text-right" @submit.prevent="handleOk" method="post">
          <div class="form-group">
            <label for="Products-location">الاسم الكامل</label>

            <input
              type="text"
              name="name"
              v-model="body.name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="Products-location">الهاتف</label>

            <input
              type="text"
              name="phone"
              v-model="body.phone"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="Products-status">ملاحظات</label>

            <textarea
              name="note"
              v-model="body.note"
              rows="3"
              class="form-control"
            ></textarea>
          </div>
          <div class="form-group">
            <Button @click="display = false" class="p-button-danger mr-1"
              >الغاء</Button
            >
            <Button type="submit" class="mr-1">طلب</Button>
          </div>
        </form>
      </Dialog>

      <div>
        <div class="">
          <section class="_bk_11hm9_9">
            <div>
              <div class="container text-center">
                <div class="row">
                  <div class="col-md-12">
                    <div class="_callaction_11hm9_13">
                      <div>
                        <div>
                          <div><h2>تواصل معنا</h2></div>
                          <div>
                            <p>
                              نهتم بأن نسمع منك.. يمكنك التواصل معنا في أي وقت
                            </p>
                          </div>
                          <a
                            class="btn _secondaryButton_11hm9_34 _button_11hm9_1"
                            target="_self"
                            :href="'tel:' + home.phone"
                            >اتصل بنا</a
                          ><a
                            class="btn _primaryButton_11hm9_64 _button_11hm9_1"
                            :href="'mailto:' + home.email"
                            >أرسل لنا بريدًا</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- <div style="">
        <div class="">
          <div class="">
            <div class="_testimonialsContainer_gihez_9">
              <div class="_sectionHeader_gihez_12">
                <h2>ماذا يقول عملاؤنا</h2>
                <p>
                  مئات العملاء يعتمدون على خدماتنا.. وإليك بعض مما قالوه عن
                  خدماتنا
                </p>
              </div>
              <div style="direction: ltr">
                <Carousel
                  :value="says"
                  :numVisible="4"
                  :numScroll="3"
                  :responsiveOptions="responsiveOptions2"
                >
                  <template #item="slotProps">
                    <div
                      class="_itemContainer_gihez_25 animate__animated animate__flip animate__delay-2s"
                      style="margin: 8px; direction: rtl"
                    >
                      <div class="_item_gihez_25">
                        <div class="_itemHeader_gihez_38">
                          <p>
                            {{ slotProps.data.body }}
                          </p>
                        </div>
                        <div class="_itemFooter_gihez_42">
                          <div class="_imgContainer_gihez_45">
                            <div
                              class="_authorImage_gihez_50"
                              :style="
                                `padding-top: 100%;
                              background-image: url('` +
                                $baseUploadURL +
                                slotProps.data.image +
                                `');
                              background-repeat: no-repeat;
                              background-position: center center;
                              background-size: cover;`
                              "
                            ></div>
                          </div>
                          <div class="_footerContent_gihez_53">
                            <h4>{{ slotProps.data.name }}</h4>
                            <h6>{{ slotProps.data.job }}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="">
          <section class="_body_1l2wf_19 container">
            <div class="_intro_1l2wf_22">
              <div class="_text_1l2wf_30"><h2>اعمالنا</h2></div>
              <div class="_text_1l2wf_30">
                <p>أمثلة حيَّة رائعة لأعمالنا</p>
              </div>
            </div>
            <div class="_galleryContainer_1l2wf_1">
              <Galleria
                :value="works"
                :activeIndex.sync="activeIndex"
                :responsiveOptions="responsiveOptions3"
                :numVisible="7"
                containerStyle="max-width: 850px"
                :circular="true"
                :fullScreen="true"
                :showItemNavigators="true"
                :showThumbnails="false"
                :visible.sync="displayCustom"
              >
                <template #item="slotProps">
                  <img
                    :src="$baseUploadURL + slotProps.item.image"
                    style="width: 100%; display: block"
                  />
                </template>
                <template #thumbnail="slotProps">
                  <img
                    :src="$baseUploadURL + slotProps.item.image"
                    style="display: block"
                  />
                </template>
              </Galleria>
              <div class="_row_1l2wf_6 row">
                <div
                  class="_imageCol_1l2wf_11 col-md-4 col-sm-2"
                  v-for="(image, index) of works"
                  :key="index"
                >
                  <div style="cursor: pointer" @click="imageClick(index)">
                    <div>
                      <div
                        class="_itemImage_1l2wf_15"
                        :style="
                          `padding-top: 100%;
                          background-image: url('` +
                          $baseUploadURL +
                          image.image +
                          `');
                          background-repeat: no-repeat;
                          background-position: center center;
                          background-size: cover;`
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Galleria from "primevue/galleria";
export default {
  name: "HomeView",
  components: {
    Galleria,
  },
  data() {
    return {
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      responsiveOptions2: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
      responsiveOptions3: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      slider: [],
      home: [],
      sections: [],
      says: [],
      works: [],
      displayCustom: false,
      activeIndex: 0,
      display: false,
      body: {
        name: null,
        phone: null,
        note: null,
        baytype: "cash",
        productsId: null,
      },
    };
  },
  methods: {
    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
    openBey(id) {
      this.display = true;
      this.body.productsId = id;
    },
    handleOk() {
      if (this.body.name && this.body.phone) {
        this.$http.post(`orders`, this.body).then(
          (res) => {
            this.display = false;
            this.body.name = null;
            this.body.phone = null;
            this.body.note = null;
            this.body.baytype = "cash";
            this.$toast.add({
              severity: "success",
              summary: "تم بنجاح",
              detail: "تم ارسال طلبك بنجاح",
              life: 3000,
            });
          },
          () => {
            this.$toast.add({
              severity: "error",
              summary: "هناك خطأ",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.$toast.add({
          severity: "error",
          summary: "هناك خطأ",
          detail: "يرجي ملئ الحقول",
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.get("home").then((res) => {
      this.sections = res.data.sections;
      this.home = res.data.home;
      this.slider = res.data.slider;
      this.says = res.data.says;
      this.works = res.data.works;
    });
  },
};
</script>
