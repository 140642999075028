<template>
  <div class="siteBody rtl">
    <div>
      <div class="">
        <section class="_outerContainer_jpy2i_9">
          <div class="row mx-0">
            <div class="col-md-6 px-0">
              <div
                class="_containerImage_jpy2i_12"
                style="
                  background-image: url('/S02-094.jpg');
                  background-position: center center;
                  background-size: cover;
                "
              >
                <div class="_overlay_jpy2i_23"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="_intro_jpy2i_34">
                <h2>تواصل معنا</h2>
                <p>نحن هنا للاجابه عن استفساراتك و تعليقاتك</p>
              </div>
              <div class="_contactInfo_jpy2i_47">
                <div class="_infoItem_jpy2i_55">
                  <div>
                    <h4 class="_label_jpy2i_60">العنوان</h4>
                    <p>
                      {{ home.address }}
                    </p>
                  </div>
                </div>
                <div class="_infoItem_jpy2i_55">
                  <div>
                    <h4 class="_label_jpy2i_60">البريد الالكتروني</h4>
                    <p>{{ home.email }}</p>
                  </div>
                </div>
                <div class="_infoItem_jpy2i_55">
                  <div>
                    <h4 class="_label_jpy2i_60">الهاتف</h4>
                    <p>{{ home.phone }}</p>
                  </div>
                </div>
                <h4 class="_socialTitle_jpy2i_70">Social Media</h4>
                <div class="_socialLinks_jpy2i_77">
                  <ul>
                    <li>
                      <a :href="home.fB"
                        ><i
                          class="pi pi-facebook"
                          style="font-size: 35px; vertical-align: middle"
                        ></i
                      ></a>
                    </li>
                    <li>
                      <a :href="home.youTube"
                        ><i
                          class="pi pi-youtube"
                          style="font-size: 35px; vertical-align: middle"
                        ></i
                      ></a>
                    </li>
                    <li>
                      <a :href="home.insta"
                        ><i
                          class="pi pi-instagram"
                          style="font-size: 35px; vertical-align: middle"
                        ></i
                      ></a>
                    </li>
                    <li>
                      <a :href="home.tikTok"
                        ><svg
                          x="0px"
                          y="0px"
                          viewBox="0 0 64 64"
                          style="width: 38px; height: 38px"
                          xml:space="preserve"
                        >
                          <path
                            style="fill: currentcolor"
                            d="M 23.773438 12 C 12.855437 12 12 12.854437 12 23.773438 L 12 40.226562 C 12 51.144563 12.855438 52 23.773438 52 L 40.226562 52 C 51.144563 52 52 51.145563 52 40.226562 L 52 23.773438 C 52 12.854437 51.145563 12 40.226562 12 L 23.773438 12 z M 21.167969 16 L 42.832031 16 C 47.625031 16 48 16.374969 48 21.167969 L 48 42.832031 C 48 47.625031 47.624031 48 42.832031 48 L 21.167969 48 C 16.374969 48 16 47.624031 16 42.832031 L 16 21.167969 C 16 16.374969 16.374969 16 21.167969 16 z M 32.740234 19.693359 L 32.740234 36.294922 C 32.740234 38.576922 30.756031 39.755859 29.332031 39.755859 C 28.259031 39.755859 25.818359 38.914578 25.818359 36.267578 C 25.818359 33.488578 28.095422 32.779297 29.357422 32.779297 C 30.092422 32.779297 30.380859 32.9375 30.380859 32.9375 L 30.380859 28.507812 C 30.380859 28.507813 29.830172 28.425781 29.201172 28.425781 C 24.682172 28.425781 21.464844 32.083578 21.464844 36.267578 C 21.464844 39.802578 24.229297 44.082031 29.279297 44.082031 C 34.658297 44.082031 37.121094 39.595969 37.121094 36.292969 L 37.121094 28.058594 C 37.121094 28.058594 39.518422 29.736328 42.732422 29.736328 L 42.732422 25.541016 C 39.045422 25.278016 37.0745 22.801359 36.9375 19.693359 L 32.740234 19.693359 z"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          'https://api.whatsapp.com/send?phone=' + home.whatsapp
                        "
                        ><i
                          class="pi pi-whatsapp"
                          style="font-size: 35px; vertical-align: middle"
                        ></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div
      style="
        background-color: rgb(255, 255, 255);
        background-position: center center;
        background-size: cover;
      "
    >
      <div class="">
        <section class="_bk_1dn4m_9">
          <div></div>
          <div class="undefined container">
            <div class="_clientTitle_1dn4m_29">
              <h2>عملاؤنا</h2>
              <p>
                مجموعة من أكبر العلامات التجارية في العالم تثق بنا في خدماتهم
              </p>
            </div>
            <div>
              <swiper
                :slides-per-view="5"
                :space-between="50"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
              >
                <swiper-slide v-for="item of clients" :key="item.id">
                  <div class="_item_1dn4m_49">
                    <div class="_imageContainer_1dn4m_19">
                      <img
                        :src="$baseUploadURL + item.image"
                        :alt="item.name"
                      />
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <!-- <div class="row">
                <div
                  class="_space_1dn4m_63 col-md-3 col-sm-6 col-6"
                
                ></div>
              </div> -->
            </div>
          </div>
        </section>
      </div>
    </div>
    <div style="background-position: center center; background-size: cover">
      <div class="">
        <footer class="_footer_1w5sa_1">
          <div class="container">
            <div class="row">
              <div
                class="col-md-3 d-flex align-items-center justify-content-center mb-md-0 mb-4"
              >
                <div
                  class="_footerLogo_1w5sa_6 animate__animated animate__rotateInDownRight animate__delay-2s"
                >
                  <router-link to="/"
                    ><img
                      style="max-height: 50px; height: 50px"
                      :src="$baseUploadURL + home.logo"
                      alt=""
                  /></router-link>
                </div>
              </div>
              <div class="col-md-6 d-flex justify-content-center">
                <ul class="NavMenu_menu__g7z03 _footerLinks_1w5sa_14">
                  <li class="_linkItem_1w5sa_22">
                    <router-link to="/" class="NavMenu_menuItem__8rDhD"
                      ><span class=""></span
                      ><span class="NavMenu_pageName__UHhMp"
                        >الرئيسية</span
                      ></router-link
                    >
                  </li>
                  <li
                    class="_linkItem_1w5sa_22"
                    v-for="item of pages"
                    :key="item.id"
                  >
                    <router-link
                      :to="'/' + item.slug"
                      class="NavMenu_menuItem__8rDhD"
                      ><span class=""></span
                      ><span class="NavMenu_pageName__UHhMp">{{
                        item.name
                      }}</span></router-link
                    >
                  </li>
                </ul>
              </div>
              <div
                class="col-md-3 d-flex flex-column justify-content-center align-items-center mt-md-0 mt-4"
              >
                <div
                  class="_footerSocial_1w5sa_34 animate__animated animate__rotateInDownLeft animate__delay-2s"
                >
                  <ul>
                    <li>
                      <a :href="home.fB"
                        ><i
                          class="pi pi-facebook"
                          style="font-size: 35px; vertical-align: middle"
                        ></i
                      ></a>
                    </li>
                    <li>
                      <a :href="home.youTube"
                        ><i
                          class="pi pi-youtube"
                          style="font-size: 35px; vertical-align: middle"
                        ></i
                      ></a>
                    </li>
                    <li>
                      <a :href="home.insta"
                        ><i
                          class="pi pi-instagram"
                          style="font-size: 35px; vertical-align: middle"
                        ></i
                      ></a>
                    </li>
                    <li>
                      <a :href="home.tikTok"
                        ><svg
                          x="0px"
                          y="0px"
                          viewBox="0 0 64 64"
                          style="width: 38px; height: 38px"
                          xml:space="preserve"
                        >
                          <path
                            style="fill: currentcolor"
                            d="M 23.773438 12 C 12.855437 12 12 12.854437 12 23.773438 L 12 40.226562 C 12 51.144563 12.855438 52 23.773438 52 L 40.226562 52 C 51.144563 52 52 51.145563 52 40.226562 L 52 23.773438 C 52 12.854437 51.145563 12 40.226562 12 L 23.773438 12 z M 21.167969 16 L 42.832031 16 C 47.625031 16 48 16.374969 48 21.167969 L 48 42.832031 C 48 47.625031 47.624031 48 42.832031 48 L 21.167969 48 C 16.374969 48 16 47.624031 16 42.832031 L 16 21.167969 C 16 16.374969 16.374969 16 21.167969 16 z M 32.740234 19.693359 L 32.740234 36.294922 C 32.740234 38.576922 30.756031 39.755859 29.332031 39.755859 C 28.259031 39.755859 25.818359 38.914578 25.818359 36.267578 C 25.818359 33.488578 28.095422 32.779297 29.357422 32.779297 C 30.092422 32.779297 30.380859 32.9375 30.380859 32.9375 L 30.380859 28.507812 C 30.380859 28.507813 29.830172 28.425781 29.201172 28.425781 C 24.682172 28.425781 21.464844 32.083578 21.464844 36.267578 C 21.464844 39.802578 24.229297 44.082031 29.279297 44.082031 C 34.658297 44.082031 37.121094 39.595969 37.121094 36.292969 L 37.121094 28.058594 C 37.121094 28.058594 39.518422 29.736328 42.732422 29.736328 L 42.732422 25.541016 C 39.045422 25.278016 37.0745 22.801359 36.9375 19.693359 L 32.740234 19.693359 z"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          'https://api.whatsapp.com/send?phone=' + home.whatsapp
                        "
                        ><i
                          class="pi pi-whatsapp"
                          style="font-size: 35px; vertical-align: middle"
                        ></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>

    <footer class="footer_area">
      <div class="footer_top_area section_dark">
        <div class="container">
          <div class="row footer_padding_top">
            <div
              class="col-md-4 col-lg-4 animate__animated animate__rotateInDownRight animate__delay-2s"
            >
              <div class="footer_adddress s_footer">
                <div>
                  <i class="pi pi-home"></i>
                </div>
                <p class="uppercase">العنوان</p>
                <p>
                  {{ home.address }}
                </p>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 animate fadeInLeftBig animated">
              <div class="footer_call_us s_footer">
                <div>
                  <i class="pi pi-phone"></i>
                </div>
                <p class="uppercase">الاتصال السريع</p>
                <p>{{ home.phone }}</p>
              </div>
            </div>
            <div
              class="col-md-4 col-lg-4 animate__animated animate__rotateInDownLeft animate__delay-2s"
            >
              <div class="footer_email_us s_footer">
                <div>
                  <i class="pi pi-envelope"></i>
                </div>
                <p class="uppercase">البريد الالكترونى</p>
                <p>{{ home.email }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="footer_border"></div>
            </div>
          </div>
          <div class="row footer_padding_bottom">
            <div class="col-md-3 col-lg-3">
              <div class="footer_discription">
                <h3>عن الشركة</h3>
                <p>
                  {{ home.aboutUs }}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="footer_list">
                <h3>اهم الروبط</h3>
                <div class="row">
                  <div class="col-md-6">
                    <ul>
                      <li v-for="item of pages">
                        <router-link :to="'/page/' + item.slug">
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul>
                      <li v-for="item of sections">
                        <router-link :to="'/sections/' + item.slug">
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 animate fadeInLeftBig animated">
              <div class="footer_opening_time">
                <h3>ساعات العمل</h3>
                <p>مفتوح <br /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom_area">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="footer_copyright animate fadeInLeftBig animated">
                <p>جميع الحقوق محفوظة 2023 © sada</p>
                <p>
                  Designed by :
                  <a href="https://alwanlab.com"> AlwanLab</a>
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="footer_menu animate fadeInLeftBig animated">
                <nav>
                  <ul></ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  name: "app-footer",
  props: {
    clients: Array,
    home: {
      logo: String,
      phone: String,
    },
    pages: Array,

    sections: Array,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>

<style>
.section_dark {
  background: #29282e;
  color: #fff;
}
.footer_padding_top {
  padding-bottom: 10px;
  padding-top: 20px;
}
.s_footer {
  border: 1px solid #3e3d43;
  color: #a39e9b;
  margin-top: 20px;
  padding: 10px 80px 10px 10px;
  position: relative;
}
.s_footer .uppercase {
  color: #fff;
  text-align: right;
  font-size: 14px;
  color: #115ea6;
  font-weight: 100;
}
.s_footer p {
  text-align: right;
  font-size: 11px;
  color: #a39e9b;
  font-weight: 100;
}
.footer_padding_bottom {
  padding: 30px 0;
}
.footer_discription {
  font-family: lato;
  line-height: 21px;
}
.footer_top_area h3 {
  font-size: 17px;
  font-weight: 100;
  text-transform: uppercase;
  text-align: right;
}
.footer_discription p {
  color: #6d6d6d;
  text-align: right;
  font-size: 12px;
  line-height: 1.9;
  font-weight: 100;
}
.footer_list ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.footer_list ul li {
  background: rgba(0, 0, 0, 0) url("../assets/image/footer_menu_arrow_ar.png")
    no-repeat scroll right center;
  padding-right: 15px;
  text-align: right;
}
.footer_list ul li a {
  color: #115ea6;
  display: block;
  padding: 2px;
  font-weight: 100;
  font-size: 12px;
}
.footer_opening_time p {
  color: #6d6d6d;
  text-align: right;
  font-size: 12px;
}
.footer_bottom_area {
  background: #232227 none repeat scroll 0 0;
  color: #fff;
  padding: 15px;
}
.footer_copyright p {
  font-size: 10px;
  text-align: right;
  font-weight: 100;
}

.footer_copyright p {
  font-size: 10px;
  text-align: right;
  font-weight: 100;
}
.footer_copyright a {
  color: #115ea6;
}
.s_footer > div {
  background: #115ea6 none repeat scroll 0 0;
  font-size: 30px;
  height: 42px;
  right: 19px;
  position: absolute;
  text-align: center;
  top: -10px;
  width: 42px;
  color: #fff;
}
.footer_adddress:hover div,
.footer_call_us:hover div,
.footer_email_us:hover div {
  background: #686767;
}
</style>
