<template>
  <div>

    <Toast position="top-left" />
    <AppHeader :sections="sections" :home="home" :pages="pages"  />
    <router-view />
    <AppFooter :home="home" :clients="clients" :pages="pages" />
  </div>
</template>

<script>
import AppHeader from './layout/header.vue';
import AppFooter from './layout/footer.vue';
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      sections: [],
      clients: [],
      pages: [],
      home: {},
    };
  },
  created() {
    this.$http.get('home/layout').then((res) => {
      this.sections = res.data.sections;
      this.clients = res.data.clients;

      this.home = res.data.home;
      this.pages = res.data.pages;
    });
  },
};
</script>
<style></style>
