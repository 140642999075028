import ToastService from 'primevue/toastservice';

export const helperApp = (Vue: any) => {
  Vue.use(ToastService);

 
  
  Vue.config.globalProperties.$errorMsg = (message: string) => {
    Vue.config.globalProperties.$toast.add({
      severity: 'error',
      summary: 'هناك خطأ',
      detail: message,
      life: 3000,
    });
  };

  Vue.config.globalProperties.$successMsg = (message: string) => {
    Vue.config.globalProperties.$toast.add({
      severity: 'success',
      summary: 'تم بنجاح',
      detail: message,
      life: 3000,
    });
  };

  

  return Vue;
};
