import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { axiosApp } from './helpers/axios';
import { helperApp } from './helpers/helper';
import 'animate.css';
import '@/assets/css/2de49ecd521d30fb.css';
import '@/assets/css/65fbad0bebfd2e42.css';
import '@/assets/css/ea42ba47a6da8ae5.css';
import '@/assets/css/5454588887777s5s.css';
import '@/assets/css/style.css';

import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css';

import PrimeVue from 'primevue/config';
let app = createApp(App);

app = axiosApp(app);

app = helperApp(app);

import Button from 'primevue/button';
import Carousel from 'primevue/carousel';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';

import ToastService from 'primevue/toastservice';
app.use(ToastService);

app.component('Dialog', Dialog);
app.component('Button', Button);
app.component('Carousel', Carousel);
app.component('Toast', Toast);
app.use(PrimeVue);

app.use(router).mount('#app');
