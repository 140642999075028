import axios from 'axios';
// Install VueCookies

export const axiosApp = (Vue: any) => {
  // const baseURL = 'http://localhost:4466';
  const baseURL = 'https://sada-eg.com:4456';

  Vue.config.globalProperties.$baseUploadURL = baseURL + '/uploads/';

  

  Vue.config.globalProperties.$http = axios.create({
    baseURL: `${baseURL}/api/`,
    headers: {
      // Authorization: `Bearer ${user?.token}`,
    },
  });

  return Vue;
};
